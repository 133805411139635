import React, { useState } from 'react';
import { useCart } from '../../context/CartContext';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './Checkout.css';
import ConsultationBooking from '../ConsultationBooking/ConsultationBooking';

// Load Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({ isBookingComplete }) => {
  const { cart, clearCart } = useCart(); // Access clearCart from the cart context
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0) * 100; // Amount in cents

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      // Create a payment intent on the server
      const { data } = await axios.post('/create-payment-intent/', {
        amount: totalPrice, // Send total amount in smallest currency unit (e.g., cents)
      });

      const clientSecret = data.client_secret;

      // Confirm the payment with Stripe
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            email: email,
          },
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          // Send email confirmation
          await axios.post('/payment-success/', {
            email: email,
            name: name,
            cart_items: cart.map(item => ({
              title: item.title,
              price: item.price,
              quantity: item.quantity
            })),
            total_price: (totalPrice / 100).toFixed(2)
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          // Clear the cart
          clearCart();

          // Redirect to the home page after successful payment
          navigate('/');

          alert('Payment successful! You will be redirected to the homepage.');
        }
      }
    } catch (err) {
      setError('Payment failed. Please try again.');
    }

    setIsProcessing(false);
  };

  // Only show the checkout form if booking is complete
  return isBookingComplete ? (
    <form onSubmit={handleSubmit} className="checkout-form">
      <h3>Your Cart</h3>
      <ul>
        {cart.map((item) => (
          <li key={item.id}>
            {item.title} ${item.price} x {item.quantity}
          </li>
        ))}
      </ul>
      <h3>Total: ${(totalPrice / 100).toFixed(2)}</h3>

      {/* Input Fields */}
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      {/* Stripe Card Element */}
      <div className="form-group">
        <label>Card Details:</label>
        <CardElement />
      </div>

      {/* Error Handling */}
      {error && <p className="error-text">{error}</p>}

      <button type="submit" disabled={isProcessing || !stripe}>
        {isProcessing ? 'Processing...' : 'Pay'}
      </button>
    </form>
  ) : (
    <div className="booking-reminder">
      <p>Please book a consultation before proceeding to checkout.</p>
    </div>
  );
};

// Main Checkout Component
const Checkout = () => {
  const [isBookingComplete, setIsBookingComplete] = useState(false); // Track booking status

  // Callback function for ConsultationBooking to set booking completion
  const handleBookingComplete = () => {
    setIsBookingComplete(true);
  };

  return (
    <Elements stripe={stripePromise}>
      <ConsultationBooking onBookingComplete={handleBookingComplete} />
      <CheckoutForm isBookingComplete={isBookingComplete} />
    </Elements>
  );
};

export default Checkout;