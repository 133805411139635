// src/renderComponents.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Banner from './components/Banner/Banner';
import AboutUs from './components/About/AboutUs';
import Showcase from './components/Showcase/Showcase';
import ContactUs from './components/Contact/ContactUs';
import Cart from './components/Cart/Cart';
import Scroll from './components/ScrollToTop/ScrollToTop';
import { AuthProvider } from './context/AuthContext';
import { CartProvider } from './context/CartContext';
import Checkout from './components/Checkout/Checkout';
import IncludedServices from './components/IncludedServices/IncludedServices';

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <div id="navbar-root"><Navbar /></div>

          <Routes>
            {/* Render multiple components for the homepage */}
            <Route path="/" element={
              <>

                <Banner />
                {/* <Banner /> */}
                <AboutUs />
                <IncludedServices />
                <Showcase />
                <ContactUs />
              </>
            } />
            {/* Other routes */}
            <Route path="/cart" element={<div id="cart-root"><Cart /></div>} />
            <Route path="/checkout" element={<div id="checkout-root"><Checkout /></div>} />
          </Routes>

          <div id="footer-root"><Footer /></div>
          <div id="scroll-root"><Scroll /></div>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.getElementById('app-root');
  if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
});