import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Showcase.css';
import { useCart } from '../../context/CartContext';

const Showcase = () => {
  const [products, setProducts] = useState([]);
  const { addToCart } = useCart();

  useEffect(() => {
    axios.get('/api/products/')
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  const handlePreview = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="showcase" id='showcase'>
      <h2>Showcase</h2>
      <div className="showcase-grid">
        {products.length === 0 ? (
          <p>No products available at the moment.</p>
        ) : (
          products.map((product) => (
            <div className="showcase-item" key={product.id}>

              <a href={product.url} target="_blank" rel="noopener noreferrer">
                <div className="showcase-iframe">
                  <iframe title={product.title} src={product.url} />
                </div>
              </a>
              <div className="showcase-content">
                <h3>{product.title}</h3>
                <p>{product.description}</p>
                <p>{product.price ? `$${product.price}` : 'Price not available'}</p>
                <button onClick={() => handlePreview(product.url)}>Preview</button>
                <button onClick={() => addToCart(product)}>Add to Cart</button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Showcase;