// src/components/IncludedServices/IncludedServices.js
import React from 'react';
import './IncludedServices.css';

const services = [
  {
    title: "Custom Branding",
    description: "We adapt our web templates to fit your unique branding and design guidelines."
  },
  {
    title: "Web Hosting",
    description: "Secure, fast, and reliable hosting for your website, included in all packages."
  },
  {
    title: "Email Setup",
    description: "Professional email addresses (e.g., yourname@yourbusiness.com) setup included."
  },
  {
    title: "SEO Optimization",
    description: "We provide basic SEO to help your website rank better in search engines."
  },
  {
    title: "Responsive Design",
    description: "Your website will be fully mobile-responsive, working seamlessly across devices."
  },
  {
    title: "Ongoing Support",
    description: "We offer ongoing support for updates and maintenance within working hours."
  },
  {
    title: "Domain Management",
    description: "Assistance with domain registration and management is available."
  },
];

const IncludedServices = () => {
  return (
    <section className="included-services">
      <h2 className="section-title">What’s Included in Our Website Service</h2>
      <ul className="services-list">
        {services.map((service, index) => (
          <li key={index} className="service-item">
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default IncludedServices;