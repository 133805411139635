import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ConsultationBooking.css'; // Custom styles
import { csrfToken } from '../csrf'; // Import the CSRF token helper

const ConsultationBooking = ({ onBookingComplete }) => {
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  
  // Honeypot field state (bots might fill this, users won't see it)
  const [honeypot, setHoneypot] = useState('');

  useEffect(() => {
    axios.get('/api/slots/')
      .then(response => setSlots(response.data))
      .catch(error => console.error('Error fetching slots:', error));
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);

    // Filter available slots for the selected date
    const formattedDate = date.toISOString().split('T')[0]; // 'YYYY-MM-DD'
    const filteredSlots = slots.filter(slot => {
      const slotDate = slot.start_datetime.split('T')[0];  // Extract the date part of start_datetime
      return slotDate === formattedDate;
    });
    setAvailableSlots(filteredSlots);
  };

  const handleSubmit = () => {
    // If honeypot field is filled, we assume it's a bot and reject the form submission
    if (honeypot) {
      console.log("Bot detected! Form submission blocked.");
      return;  // Stop form submission
    }

    if (!selectedSlot) {
      alert('Please select a time slot.');
      return;
    }

    axios.post('/api/bookings/', {
      slot: selectedSlot.id,
      name: name,
      email: email,
    }, {
      headers: {
        'X-CSRFToken': csrfToken  // Include the CSRF token in the headers
      }
    }).then(response => {
      alert('Consultation booked successfully!');
      
      // Notify parent that booking is complete
      if (onBookingComplete) {
        onBookingComplete();  // Trigger the callback
      }
    }).catch(error => {
      console.error('Error booking consultation:', error);
      alert('Error booking consultation.');
    });
  };

  const isDateAvailable = (date) => {
    const formattedDate = date.toISOString().split('T')[0];  // Ensure UTC date
    return slots.some(slot => {
      const slotDate = slot.start_datetime.split('T')[0];  // Extract the date part
      return slotDate === formattedDate && slot.is_available;
    });
  };

  const highlightDates = slots.map(slot => {
    const date = new Date(slot.start_datetime);  // Convert to Date object
  
    if (isNaN(date.getTime())) {
      console.error(`Invalid date found in slot: ${slot.start_datetime}`);
      return null;
    }
  
    return {
      date: date,  // Only use valid dates
      is_available: slot.is_available,
    };
  }).filter(slot => slot !== null);  // Filter out any invalid slots

  return (
    <div className="consultation-booking-container">
      <h3>Book a Consultation</h3>

      <div className="consultation-content">
        {/* Calendar with available/unavailable dates */}
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          inline
          highlightDates={[
            {
              "react-datepicker__day--highlighted-available": highlightDates
                .filter(slot => slot.is_available)
                .map(slot => slot.date),  // Pass valid Date objects
            },
            {
              "react-datepicker__day--highlighted-unavailable": highlightDates
                .filter(slot => !slot.is_available)
                .map(slot => slot.date),  // Pass valid Date objects
            },
          ]}
          dayClassName={date => 
            isDateAvailable(date) ? 'available' : 'unavailable'
          }
        />

        {/* Booking Form and Time Slots */}
        <div className="booking-form-container">
          <div className="timeslots">
            <h4>Select a Time Slot</h4>
            <select
              size="2"
              onChange={(e) => {
                const selectedSlot = availableSlots.find(slot => slot.id === parseInt(e.target.value));
                setSelectedSlot(selectedSlot);
              }}
              className="scrollable-select"
            >
              {availableSlots.map(slot => (
                <option key={slot.id} value={slot.id}>
                  {new Date(slot.start_datetime).toLocaleString()} - {new Date(slot.end_datetime).toLocaleString()}
                </option>
              ))}
            </select>
          </div>

          <div className="booking-form">
            <label>Name:</label>
            <input type="text" value={name} onChange={e => setName(e.target.value)} required />

            <label>Email:</label>
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />

            {/* Honeypot Field (hidden) */}
            <div style={{ display: 'none' }}>
              <label>Leave this field empty:</label>
              <input
                type="text"
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
              />
            </div>

            {/* Submit Button */}
            <button className="submit-btn" onClick={handleSubmit}>Book Consultation</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultationBooking;