// src/components/Banner/Banner.js
import React from 'react';
import './Banner.css';
import logo from '../Navbar/head_logo.svg'; // Adjust the path as necessary

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <img src={logo} alt="Mr. May Who Logo" className="banner-logo" />
        <h1 className="banner-title">Mr. May Who</h1>
      </div>
    </div>
  );
};

export default Banner;