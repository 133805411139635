// src/components/Navbar/Navbar.js
import React, { useState, useEffect, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import './Navbar.css';
import logo from './head_logo.svg'; 
import { AuthContext } from '../../context/AuthContext'; // Import AuthContext
import LoginModal from './SignInModals/LoginModal';
import RegisterModal from './SignInModals/RegisterModal';
import ConfirmModal from './SignInModals/ConfirmModal';
import { useCart } from '../../context/CartContext'; // Import CartContext
import { Link } from 'react-router-dom';

const Navbar = () => {
  const { isAuthenticated, logout, user } = useContext(AuthContext); // Use the AuthContext to get user info and logout function
  const { cart } = useCart(); // Use CartContext to get the cart
  const [isOpen, setIsOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleLoginModal = () => {
    setShowLogin(!showLogin);
  };

  const toggleRegisterModal = () => {
    setShowRegister(!showRegister);
  };

  const toggleConfirmModal = () => {
    setShowConfirm(!showConfirm);
  };

  useEffect(() => {
    // Check if the URL contains the query parameter 'confirmed'
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('confirmed')) {
      setShowConfirm(true);
    }
  }, []);

  const menuAnimation = useSpring({
    height: isOpen ? '50vh' : '0px',
    opacity: isOpen ? 1 : 0,
    overflow: 'hidden',
    config: {
      tension: 450,
      friction: 20,
    },
  });

  const cartItemCount = cart.reduce((total, item) => total + item.quantity, 0); // Calculate the total quantity of items in the cart

  return (
    <>
      <nav className="navbar" id="home">
        <div className="navbar-brand">
            <Link to="/">
              <img src={logo} alt="Mr. May Who Logo" className="navbar-logo" />
            </Link>
        </div>
        <div className="cart-icon">
        <Link to="/cart">
          <i className="fas fa-shopping-cart"></i> {/* Cart icon */}
          {cartItemCount > 0 && <span className="cart-badge">{cartItemCount}</span>} {/* Badge */}
        </Link>
        </div>
        <button className="navbar-toggler" onClick={handleToggle}>
          &#9776;
        </button>
        <animated.ul style={menuAnimation} className="navbar-menu">
          <li className="navbar-item">
            <Link to="/">
              Home
            </Link></li>
          <li className="navbar-item"><a href="#about-us">About</a></li>
          <li className="navbar-item"><a href="#showcase">Showcase</a></li>
          <li className="navbar-item"><a href="#contact">Contact</a></li>

          {isAuthenticated ? (
            <>
              <li className="navbar-item">
                <span className="navbar-user">Welcome, {user.email || user.username}</span>
              </li>
              <li className="navbar-item">
                <button onClick={logout} className="auth-button">Logout</button>
              </li>
            </>
          ) : (
            <>
              <li className="navbar-item">
                <button onClick={toggleLoginModal} className="auth-button">Login</button>
              </li>
              <li className="navbar-item">
                <button onClick={toggleRegisterModal} className="auth-button">Register</button>
              </li>
            </>
          )}
        </animated.ul>

      </nav>

      {/* Login Modal */}
      {showLogin && <LoginModal toggleModal={toggleLoginModal} />}

      {/* Register Modal */}
      {showRegister && <RegisterModal toggleModal={toggleRegisterModal} />}

      {/* Confirm Modal */}
      {showConfirm && <ConfirmModal toggleModal={toggleConfirmModal} />}
    </>
  );
};

export default Navbar;