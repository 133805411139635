// src/components/AboutUs/AboutUs.js
import React, { useEffect, useState } from 'react';
import { useSprings, animated } from 'react-spring';
import './AboutUs.css';

const AboutUs = () => {
  const paragraph = "At Mr. May Who, we are dedicated to empowering small business owners by providing them with user-friendly, professionally designed websites and intuitive tools to manage their businesses with ease. Our experienced team works closely with each client to understand their unique needs and deliver tailored solutions that help streamline operations and enhance their online presence. We are committed to delivering high-quality, personalized service and ongoing support, ensuring that your business is equipped to thrive in the digital landscape."
  const words = paragraph.split(' ');
  const [inView, setInView] = useState(false);

  const [springs, api] = useSprings(words.length, index => ({
    opacity: 0,
    transform: 'translateX(-20px)',
    config: { tension: 200, friction: 20 },
  }));

  useEffect(() => {
    const handleScroll = () => {
      const aboutUsElement = document.getElementById('about-us');
      const rect = aboutUsElement.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        setInView(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (inView) {
      api.start(index => ({
        opacity: 1,
        transform: 'translateX(0px)',
        delay: index * 100,
      }));
    }
  }, [inView, api]);

  return (
    <div id="about-us" className="about-us">
      <h2>About Us</h2>
      <p>
        {springs.map((style, index) => (
          <animated.span key={index} style={style}>
            {words[index]}{' '}
          </animated.span>
        ))}
      </p>
    </div>
  );
};

export default AboutUs;